import React from 'react'
import Footer from '../components/Footer'
import MenuFa from '../components/MenuFa'
export default function AboutFa() {
  return (
      <React.Fragment>
          

    <div className="bg-homeBg min-h-screen dark:bg-homeBg-dark bg-no-repeat bg-center bg-cover bg-fixed md:pb-16 w-full">
        <div className="z-50">
     
            <div className="container">
             
                <MenuFa/>

                <div>
                    <div className="container lg:rounded-2xl bg-white dark:bg-[#111111] px-4 sm:px-5 md:px-10 lg:px-20">
                        <div data-aos="fade" className="aos-init aos-animate">
                            <div className="py-12">
                           
                                <h2 className="after-effect after:right-10 mb-[40px] mt-12 lg:mt-0 flex items-center space-x-2 mb-4 flexrtl"> درباره من </h2>
                                <div className="grid grid-cols-12 md:gap-10 pt-4 md:pt-[40px] items-center">
                                    <div className="col-span-12 md:col-span-4">
                                        
                                        <img className="w-full md:w-[330px] md:h-[400px] object-cover overflow-hidden rounded-[35px] mb-3 md:mb-0"
                                            src="/assets/images/about/about.jpg" alt="about " />
                                    </div>
                                    <div className="col-span-12 md:col-span-8 space-y-2.5">
                                        
                                        <div className="md:mr-12 xl:mr-16">
                                            <h3 className="text-4xl font-medium dark:text-white mb-2.5 rtl">من کیم</h3>
                                            <p className="text-gray-lite dark:text-color-910 leading-7 rtl">  از 14 سالگی وارد دنیایی برنامه نویسی شدم و به زبان های برنامه نویسی علاقه پیدا کردم  </p>
                                            <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910 rtl"> 
دوساله به صورت تخصصی جاواسکریپت و فریمورک های React و vue دنبال میکنم
 </p>
                                        </div>

                                        <div>
                                            <h3 className="text-4xl font-medium my-5 dark:text-white rtl"> اطلاعات شخصی </h3>
                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                                <div className="flex">
                                                    <span
                                                        className="text-oriange dark:bg-color-990 shadow-icon mr-2.5 flex items-center justify-center rounded-md text-2xl w-12 text-">
                                                        <i className="fa-solid fa-mobile-screen-button"></i>
                                                    </span>
                                                    <div className="space-y-1">
                                                        <p className="text-xs text-gray-lite dark:text-color-910"> تلفن
                                                        </p>
                                                        <h6 className="font-medium dark:text-white"> +98 914 987 5054 </h6>
                                                    </div>
                                                </div>

                                                <div className="flex">
                                                    <span
                                                        className="text-oriange-lite dark:bg-color-990 shadow-icon mr-2.5 flex items-center justify-center rounded-md text-2xl w-12 text-">
                                                        <i className="fa-solid fa-location-dot"></i>
                                                    </span>
                                                    <div className="space-y-1">
                                                        <p className="text-xs text-gray-lite dark:text-color-910"> موقعیت مکانی
                                                        </p>
                                                        <h6 className="font-medium dark:text-white"> ایران اردبیل </h6>
                                                    </div>
                                                </div>

                                                <div className="flex">
                                                    <span
                                                        className="text-green dark:bg-color-990 shadow-icon mr-2.5 flex items-center justify-center rounded-md text-2xl w-12 text-">
                                                        <i className="fa-solid fa-envelope-open-text"></i>
                                                    </span>
                                                    <div className="space-y-1">
                                                        <p className="text-xs text-gray-lite dark:text-color-910"> ایمیل
                                                        </p>
                                                        <h6 className="font-medium dark:text-white"> M.r.sedghii@Gmail.com
                                                        </h6>
                                                    </div>
                                                </div>

                                                <div className="flex">
                                                    <span
                                                        className="text-color-50 dark:bg-color-990 shadow-icon mr-2.5 flex items-center justify-center rounded-md text-2xl w-12 text-">
                                                        <i className="fa-solid fa-calendar-days"></i>
                                                    </span>
                                                    <div className="space-y-1">
                                                        <p className="text-xs text-gray-lite dark:text-color-910"> متولد
                                                        </p>
                                                        <h6 className="font-medium dark:text-white">21/9/1377</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pb-12">
                                <h3 className="text-[35px] dark:text-white font-medium pb-5 rtl">من چیکار میکنم! </h3>
                                <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
                                    <div className="about-box dark:bg-transparent bg-[#fcf4ff]">
                                        <img className="w-10 h-10 object-contain block" src="/assets/images/icons/icon.svg"
                                            alt="icon" />
                                        <div className="space-y-2">
                                            <h3 className="dark:text-white text-2xl font-semibold rtl"> معمار </h3>
                                            <p className="leading-8 text-gray-lite dark:text-[#A6A6A6] rtl"> طراح داخلی و خارجی ساختمان </p>
                                        </div>
                                    </div>

                                    <div className="about-box dark:bg-transparent bg-[#fefaf0]">
                                        <img className="w-10 h-10 object-contain block" src="/assets/images/icons/icon1.svg"
                                            alt="icon" />
                                        <div className="space-y-2">
                                            <h3 className="dark:text-white text-2xl font-semibold"> App Development </h3>
                                            <p className="leading-8 text-gray-lite dark:text-[#A6A6A6]"> Lorem ipsum dolor
                                                sit amet, consectetuer adipiscing elit, sed diam euismod volutpat. </p>
                                        </div>
                                    </div>

                                    <div className="about-box dark:bg-transparent bg-[#fcf4ff]">
                                        <img className="w-10 h-10 object-contain block" src="/assets/images/icons/icon2.svg"
                                            alt="icon" />
                                        <div className="space-y-2">
                                            <h3 className="dark:text-white text-2xl font-semibold"> Photography </h3>
                                            <p className="leading-8 text-gray-lite dark:text-[#A6A6A6]"> Lorem ipsum dolor
                                                sit amet, consectetuer adipiscing elit, sed diam euismod volutpat. </p>
                                        </div>
                                    </div>

                                    <div className="about-box dark:bg-transparent bg-[#fff4f4]">
                                        <img className="w-10 h-10 object-contain block" src="/assets/images/icons/icon3.svg"
                                            alt="icon" />
                                        <div className="space-y-2">
                                            <h3 className="dark:text-white text-2xl font-semibold"> Photography </h3>
                                            <p className="leading-8 text-gray-lite dark:text-[#A6A6A6]"> Lorem ipsum dolor
                                                sit amet, consectetuer adipiscing elit, sed diam euismod volutpat. </p>
                                        </div>
                                    </div>

                                    <div className="about-box dark:bg-transparent bg-[#fff0f8]">
                                        <img className="w-10 h-10 object-contain block" src="/assets/images/icons/icon4.svg"
                                            alt="icon" />
                                        <div className="space-y-2">
                                            <h3 className="dark:text-white text-2xl font-semibold"> Managment </h3>
                                            <p className="leading-8 text-gray-lite dark:text-[#A6A6A6]"> Lorem ipsum dolor
                                                sit amet, consectetuer adipiscing elit, sed diam euismod volutpat. </p>
                                        </div>
                                    </div>

                                    <div className="about-box dark:bg-transparent bg-[#f3faff]">
                                        <img className="w-10 h-10 object-contain block" src="/assets/images/icons/icon5.svg"
                                            alt="icon" />
                                        <div className="space-y-2">
                                            <h3 className="dark:text-white text-2xl font-semibold"> Web Development </h3>
                                            <p className="leading-8 text-gray-lite dark:text-[#A6A6A6]"> Lorem ipsum dolor
                                                sit amet, consectetuer adipiscing elit, sed diam euismod volutpat. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                   

                      
                  <Footer/>
                            
                        </div>
                    </div>
                </div>
              
            </div>
        </div>
    </div>


    
    <script src="//assets/js/vendor/jquary.min.js"></script>
    <script src="//assets/js/vendor/slick.js"></script>
   
    <script src="//assets/js/main.js"></script>
          </React.Fragment>
  )
}
