import React from 'react'
import Footer from '../components/Footer'
import Menu from '../components/Menu'
export default function Resume() {
  return (
    <React.Fragment>
          

    <div className="bg-homeBg min-h-screen dark:bg-homeBg-dark bg-no-repeat bg-center bg-cover bg-fixed md:pb-16 w-full">
        <div className="z-50">
            
            <div className="container">
              
                
<Menu/>
            
                <section className="bg-white lg:rounded-2xl dark:bg-[#111111]">
                    <div data-aos="fade" className="aos-init aos-animate">
                        <div className="container sm:px-5 md:px-10 lg:px-20">
                            <div className="py-12 px-4">
                                <h2 className="after-effect after:left-44 mb-[40px] mt-12 lg:mt-0"> Resume </h2>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-6">
                              
                                    <div>
                                        <div className="flex items-center space-x-2 mb-4">
                                            <i className="fa-solid text-6xl text-[#F95054] fa-graduation-cap"></i>
                                            <h4 className="text-5xl dark:text-white font-medium"> Education </h4>
                                        </div>

                                        <div
                                            className="py-4 dark:bg-transparent bg-[#fff4f4] pl-5 pr-3 space-y-2 mb-6 rounded-lg dark:border-[#212425] dark:border-2">
                                            <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">2017-2019</span>
                                            <h3 className="text-xl dark:text-white"> Physical therapy </h3>
                                            <p className="dark:text-[#b7b7b7]"> Istanbul Aydin University, Istanbul, Turkey </p>
                                        </div>

                                        <div
                                            className="py-4 dark:bg-transparent bg-[#fff1fb] pl-5 pr-3 space-y-2 rounded-lg mb-6 dark:border-[#212425] dark:border-2">
                                            <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">2019 -
                                                2021</span>
                                            <h3 className="text-xl dark:text-white"> Computer software engineering - Associate degree
 </h3>
                                            <p className="dark:text-[#b7b7b7]">Sama Islamic Azad University , Ardabil, Iran</p>
                                        </div>

                                        <div
                                            className="py-4 dark:bg-transparent bg-[#fff4f4] pl-5 pr-3 space-y-2 rounded-lg dark:border-[#212425] dark:border-2">
                                            <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">2021 - Now</span>
                                            <h3 className="text-xl dark:text-white"> Computer software engineering - Master</h3>
                                            <p className="dark:text-[#b7b7b7]"> Sama Islamic Azad University , Ardabil, Iran </p>
                                        </div>

                                    </div>

                                    <div>
                                     
                                        <div className="flex items-center space-x-2 mb-4">
                                            <i className="fa-solid text-6xl text-[#F95054] fa-briefcase"></i>
                                            <h4 className="text-5xl dark:text-white font-medium"> Experience </h4>
                                        </div>

                                        <div
                                            className="py-4 dark:bg-transparent bg-[#eef5fa] pl-5 pr-3 space-y-2 mb-6 rounded-lg dark:border-[#212425] dark:border-2">
                                            <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">2016-2017</span>
                                            <h3 className="text-xl dark:text-white"> IT manager </h3>
                                            <p className="dark:text-[#b7b7b7]"> Aylin Mehr Razi Azerbaijan Co </p>
                                        </div>

                                        <div
                                            className="py-4 dark:bg-transparent bg-[#f2f4ff] pl-5 pr-3 space-y-2 rounded-lg mb-6 dark:border-[#212425] dark:border-2">
                                            <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">2020-2022</span>
                                            <h3 className="text-xl dark:text-white"> Language Teacher (Turkish) </h3>
                                            <p className="dark:text-[#b7b7b7]">Pishrovan Academy , Ardabil, Iran</p>
                                        </div>

                                        <div
                                            className="py-4 dark:bg-transparent bg-[#eef5fa] pl-5 pr-3 space-y-2 rounded-lg dark:border-[#212425] dark:border-2">
                                            <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">2020-2022</span>
                                            <h3 className="text-xl dark:text-white">Programming instructor</h3>
                                            <p className="dark:text-[#b7b7b7]"> Pascal Vocational Academy , Ardabil , Iran</p>
                                        </div>
                                    </div>

                                    <div>
                                    
                                        <div className="flex items-center space-x-2 mb-4">
                                            <i className="fa-solid fa-award text-6xl text-[#F95054]"></i>
                                            <h4 className="text-5xl dark:text-white font-medium"> 
Degree </h4>
                                        </div>

                                        <div
                                            className="py-4 dark:bg-transparent bg-[#fcf4ff]  pl-5 pr-3 space-y-2 mb-6  rounded-lg dark:border-[#212425] dark:border-2">
                                            <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">2021</span>
                                            <h3 className="text-xl dark:text-white"> Graphic Designer (Photoshop) </h3>
                                            <p className="dark:text-[#b7b7b7]">Iran Technical & Vocational Training Organization </p>
                                        </div>

                                        <div
                                            className="py-4 dark:bg-transparent bg-[#fcf9f2] pl-5 pr-3 space-y-2 rounded-lg mb-6 dark:border-[#212425] dark:border-2">
                                            <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">2021</span>
                                            <h3 className="text-xl dark:text-white"> ICDL </h3>
                                            <p className="dark:text-[#b7b7b7]">Iran Technical & Vocational Training Organization</p>
                                              </div>
                                              
                                                                                <div
                                            className="py-4 dark:bg-transparent bg-[#fcf9f2] pl-5 pr-3 space-y-2 rounded-lg mb-6 dark:border-[#212425] dark:border-2">
                                            <span className="text-tiny text-gray-lite dark:text-[#b7b7b7]">2022</span>
                                            <h3 className="text-xl dark:text-white"> 
Director </h3>
                                            <p className="dark:text-[#b7b7b7]">Iranian Youth Cinema Society</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                     
                        <div className="container bg-[#f8fbfb] dark:bg-[#0D0D0D] py-12 px-4 sm:px-5 md:px-10 lg:px-20">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                                <div className="col-span-1">
                                    <h4 className="text-5xl dark:text-white font-medium mb-6"> Working Skills </h4>
                                    <div className="mb-5">
                                        <div className="flex justify-between mb-1">
                                            <span className=" font-semibold text-[#526377] dark:text-[#A6A6A6]">Web
                                                Design</span>
                                            <span className=" font-semibold text-[#526377] dark:text-[#A6A6A6">65%</span>
                                        </div>
                                        <div className="w-full bg-[#edf2f2] rounded-full h-1 dark:bg-[#1c1c1c]">
                                            <div className="bg-[#FF6464] h-1 rounded-full width65" ></div>
                                        </div>
                                    </div>

                                    <div className="mb-5">
                                        <div className="flex justify-between mb-1">
                                            <span className=" font-semibold text-[#526377] dark:text-[#A6A6A6]">Mobile
                                                App</span>
                                            <span className=" font-semibold text-[#526377] dark:text-[#A6A6A6">85%</span>
                                        </div>
                                        <div className="w-full bg-[#edf2f2] rounded-full h-1 dark:bg-[#1c1c1c]">
                                            <div className="bg-[#9272d4] h-1 rounded-full width85" ></div>
                                        </div>
                                    </div>

                                    <div className="mb-5">
                                        <div className="flex justify-between mb-1">
                                            <span
                                                className=" font-semibold text-[#526377] dark:text-[#A6A6A6]">Illustrator</span>
                                            <span className=" font-semibold text-[#526377] dark:text-[#A6A6A6">75%</span>
                                        </div>
                                        <div className="w-full bg-[#edf2f2] rounded-full h-1 dark:bg-[#1c1c1c]">
                                            <div className="bg-[#5185d4] h-1 rounded-full width75" ></div>
                                        </div>
                                    </div>

                                    <div className="mb-5">
                                        <div className="flex justify-between mb-1">
                                            <span
                                                className=" font-semibold text-[#526377] dark:text-[#A6A6A6]">Photoshope</span>
                                            <span className=" font-semibold text-[#526377] dark:text-[#A6A6A6">90%</span>
                                        </div>
                                        <div className="w-full bg-[#edf2f2] rounded-full h-1 dark:bg-[#1c1c1c]">
                                            <div className="bg-[#ca56f2] h-1 rounded-full width90"></div>
                                        </div>
                                    </div>
                                </div>

                             
                                      

                                      
                                <div className="col-span-1">
                                    <h4 className="text-5xl dark:text-white font-medium mb-8"> Knowledges </h4>
                                    <div className="flex gap-x-3 gap-y-3 md:gap-y-6 md:gap-x-4 flex-wrap">
                                        <button className="resume-btn">Lumion</button>
                                        <button className="resume-btn">SketchUp</button>
                                        <button className="resume-btn">Photoshop</button>
                                        <button className="resume-btn">Illustrator</button>
                                        <button className="resume-btn">AfterEffect</button>
                                        <button className="resume-btn">FL Studio</button>
                                        <button className="resume-btn">Rhino Gold</button>
                                        <button className="resume-btn">Word</button>
                                        <button className="resume-btn">PowerPoint</button>
                                        <button className="resume-btn">Excel</button>
                                              <button className="resume-btn">3D Modeling</button>
                                              <button className="resume-btn">Painter</button>
                                              <button className="resume-btn">Poet</button>
                                              <button className="resume-btn">Director</button>
                                              <button className="resume-btn">Architect</button>
                                              <button className="resume-btn">Programmer</button>
                                              <button className="resume-btn">Web Designer</button>
                                        <button className="resume-btn">3D Rendering</button>
                                    </div>
                                </div>
                               
                                       <div className="col-span-1">
                                    <h4 className="text-5xl dark:text-white font-medium mb-6"> Language Skills </h4>
                                    <div className="mb-5">
                                        <div className="flex justify-between mb-1">
                                            <span className=" font-semibold text-[#526377] dark:text-[#A6A6A6]">Persian</span>
                                            <span className=" font-semibold text-[#526377] dark:text-[#A6A6A6">100%</span>
                                        </div>
                                        <div className="w-full bg-[#edf2f2] rounded-full h-1 dark:bg-[#1c1c1c]">
                                            <div className="bg-[#FF6464] h-1 rounded-full width100" ></div>
                                        </div>
                                    </div>

                                    <div className="mb-5">
                                        <div className="flex justify-between mb-1">
                                            <span className=" font-semibold text-[#526377] dark:text-[#A6A6A6]">Azerbaijani</span>
                                            <span className=" font-semibold text-[#526377] dark:text-[#A6A6A6">100%</span>
                                        </div>
                                        <div className="w-full bg-[#edf2f2] rounded-full h-1 dark:bg-[#1c1c1c]">
                                            <div className="bg-[#9272d4] h-1 rounded-full width100" ></div>
                                        </div>
                                    </div>

                                    <div className="mb-5">
                                        <div className="flex justify-between mb-1">
                                            <span
                                                className=" font-semibold text-[#526377] dark:text-[#A6A6A6]">Turkish</span>
                                            <span className=" font-semibold text-[#526377] dark:text-[#A6A6A6">100%</span>
                                        </div>
                                        <div className="w-full bg-[#edf2f2] rounded-full h-1 dark:bg-[#1c1c1c]">
                                            <div className="bg-[#5185d4] h-1 rounded-full width100" ></div>
                                        </div>
                                    </div>

                                    <div className="mb-5">
                                        <div className="flex justify-between mb-1">
                                            <span
                                                className=" font-semibold text-[#526377] dark:text-[#A6A6A6]">English</span>
                                            <span className=" font-semibold text-[#526377] dark:text-[#A6A6A6">65%</span>
                                        </div>
                                        <div className="w-full bg-[#edf2f2] rounded-full h-1 dark:bg-[#1c1c1c]">
                                            <div className="bg-[#ca56f2] h-1 rounded-full width65"></div>
                                        </div>
                                          </div>
                                          
                                          <div className="mb-5">
                                        <div className="flex justify-between mb-1">
                                            <span className=" font-semibold text-[#526377] dark:text-[#A6A6A6]">Russian</span>
                                            <span className=" font-semibold text-[#526377] dark:text-[#A6A6A6">30%</span>
                                        </div>
                                        <div className="w-full bg-[#edf2f2] rounded-full h-1 dark:bg-[#1c1c1c]">
                                            <div className="bg-[#FF6464] h-1 rounded-full width30" ></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                     
         <Footer/>
                       
                    </div>
                </section>
             
            </div>
        </div>
    </div>


    <script src="./js/vendor/jquary.min.js"></script>
  
    <script src="./js/main.js"></script>
    </React.Fragment>
  )
}
