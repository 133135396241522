import React from 'react'
import Menu from '../components/Menu'

export default function Home() {
  return (
      <React.Fragment>
          
          

    <div className="bg-homeBg min-h-screen dark:bg-homeBg-dark bg-no-repeat bg-center bg-cover bg-fixed md:pb-16 w-full">
        <div className="z-50">
          
            <div className="container">
             
             <Menu/>

        
                <div className="flex flex-col items-center h-[100vh] md:h-[90vh] lg:h-[80vh] xl:h-[71vh] justify-center aos-init aos-animate"
                    data-aos="fade">
                  
                    <img className="rounded-full w-[250px] h-[250px] 2xl:w-[280px] 2xl:h-[280px]"
                        src="assets/images/about/avatar.jpg" alt="about avatar" />
                    <h3 className="mt-6 mb-1 text-5xl font-semibold dark:text-white"> Mohammad Reza Sedghi </h3>
                    <p className="mb-4 text-[#7B7B7B]">Fullstack Web Developer</p>
                 
                    <div className="flex space-x-3">
                       
                        <a href="https://www.facebook.com/Mrsedghii" target="_blank" rel="noopener noreferrer">
                            <span className="socialbtn text-[#1773EA]">
                                <i className="fa-brands fa-facebook-f"></i>
                            </span>
                        </a>
                      
                        <a href="https://twitter.com/mr_sedghi" target="_blank" rel="noopener noreferrer">
                            <span className="socialbtn text-[#1C9CEA]">
                                <i className="fa-brands fa-twitter"></i>
                            </span>
                        </a>
                       
                        <a href="https://www.instagram.com/m.r.sedghi/" target="_blank" rel="noopener noreferrer">
                            <span className="socialbtn text-[#e14a84]">
                                <i className="fa-brands fa-instagram"></i>
                            </span>
                        </a>
                      
                        <a href="https://ir.linkedin.com/in/mohammad-reza-sedghi-871587168?trk=people-guest_people_search-card"
                            target="_blank" rel="noopener noreferrer">
                            <span className="socialbtn text-[#0072b1]">
                                <i className="fa-brands fa-linkedin-in"></i>
                            </span>
                        </a>
                        <a href="https://soundcloud.com/mohammad-reza-sedghi" target="_blank" rel="noopener noreferrer">
                            <span className="socialbtn text-[#ff870f]">
                                <i className="fa-brands fa-soundcloud"></i>
                            </span>
                        </a>
                        <a href="https://t.me/mr_sedghi" target="_blank" rel="noopener noreferrer">
                            <span className="socialbtn text-[#1C9CEA]">
                                <i className="fa-brands fa-telegram"></i>
                            </span>
                        </a>
                    </div>
                    
                    <button className="dowanload-btn">
                        <img src="assets/images/icons/dowanload.png" alt="icon" className="mr-2" />Download CV </button>
                </div>
               
            </div>
        </div>
    </div>


  

    </React.Fragment>
  )
}
