import './App.css';

import RouterPages from './pages/RouterPages';

function App() {
  return (
    <div className="App">
<RouterPages/>
    </div>
  );
}

export default App;
