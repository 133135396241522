
import React from 'react'
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom'
import Home from './Home'
import About from './About'
import Resume from './Resume'
import Portfolio from './Portfolio'
import Contact from './Contact'
import HomeFa from './HomeFa'
import AboutFa from './AboutFa'
import ResumeFa from './ResumeFa'
import PortfolioFa from './PortfolioFa'
import ContactFa from './ContactFa'
export default function RouterPages() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About/>} />
          <Route path='/resume' element={<Resume />} />
          {/* <Route path='/works' element={<Works />} /> */}
          <Route path='/portfolio' element={<Portfolio />} /> 
          <Route path='/contact' element={<Contact />} />
          <Route path='/fa/' element={<HomeFa />} />
          <Route path='/fa/about' element={<AboutFa/>} />
          <Route path='/fa/resume' element={<ResumeFa/>} />
          <Route path='/fa/portfolio' element={<PortfolioFa/>} />
          <Route path='/fa/contact' element={<ContactFa/>} />
        </Routes>
      </Router>
    </div>
  )
}
