import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { US } from 'country-flag-icons/react/3x2'

export default function MenuFa() {
 const activeLink = "menu-item-two-active";
const normalLink = "menu-item-two";
const mobActiveLink = "mobile-menu-items-active";
const mobNormalLink = "mobile-menu-items";
    
const [showMenu, setShowMenu] = useState(true)
 
    
  return (
      
             <header className="flex justify-between items-center fixed top-0 left-0 w-full lg:static z-[1111111111]">
                    <div className="flex justify-between w-full px-4 lg:px-0 bg-[#F3F6F6] lg:bg-transparent dark:bg-black height5rem" >
                        <div className="flex justify-between w-full items-center space-x-4 lg:my-8 my-5">
                        
                            <a className="text-5xl font-semibold" href="/">
                                <img className="h-20 lg:h-10" src="/assets/images/logo/logo.png" alt="logo" />
                            </a>
                            <div className="flex items-center">
                             
                                 {/* <button id="theme-toggle-mobile" type="button"
                                    className="dark-light-btn lg:hidden w-[44px] h-[44px] ml-2">
                                    <i id="theme-toggle-dark-icon-mobile" className="fa-solid text-xl fa-moon hidden"></i>
                                    <i id="theme-toggle-light-icon-mobile" className="fa-solid fa-sun text-xl hidden"></i>
                      </button>  */}
                      

                  <NavLink  to="/" id="theme-toggle-mobile" type="button"
                                    className="dark-light-btn lg:hidden w-[44px] h-[44px] ml-2">
                                    
                                    {/* <IR title="Persian" className="..." width={20}/> */}
                                    <US title="English" className="..." width={20}/> 
                                </NavLink> 
                          


                          
                                <button id="menu-toggle" type="button" className="menu-toggle-btn" onClick={() => setShowMenu(!showMenu)}>
                                    <i id="menu-toggle-open-icon" className="fa-solid fa-bars text-xl "></i>
                                    <i id="menu-toggle-close-icon" className="fa-solid fa-xmark text-xl hidden  "></i>
                                </button>
                            </div>
                        </div>
                    </div>

                  
                    <nav className="hidden lg:block">
                        <ul className="flex my-12">
                            <li>
                                <NavLink  to="/fa/" className={({ isActive }) => (isActive ? activeLink : normalLink)}>
                                    <span className="mr-2 text-base">
                                        <i className="fa-solid fa-house"></i>
                                    </span> خانه </NavLink>
                            </li>
                            <li>
                                <NavLink  to="/fa/about" className={({ isActive }) => (isActive ? activeLink : normalLink)}>
                                    <span className="mr-2 text-base">
                                        <i className="fa-regular fa-user"></i>
                                    </span> درباره </NavLink>
                            </li>
                            <li>
                                <NavLink  to="/fa/resume" className={({ isActive }) => (isActive ? activeLink : normalLink)}>
                                      <span className="mr-2 text-base">
                                        <i className="fa-regular fa-file-lines"></i>
                                    </span> رزومه </NavLink>
                            </li>
                             {/* <li>
                                <NavLink  to="/works" className={({ isActive }) => (isActive ? activeLink : normalLink)}>
                                       <span className="mr-2 text-base">
                                        <i className="fas fa-briefcase"></i>
                                    </span> Works </NavLink>
                            </li> */}
                            {/* <li>
                                 <NavLink  to="/fa/portfolio" className={({ isActive }) => (isActive ? activeLink : normalLink)}>
                                      <span className="mr-2 text-base">
                                        <i className="fa-brands fa-blogger"></i>
                                    </span> کارها </NavLink>
                            </li>  */}
                            <li>
                                 <NavLink  to="/fa/contact" className={({ isActive }) => (isActive ? activeLink : normalLink)}>
                                       <span className="mr-2 text-base">
                                        <i className="fa-solid fa-address-book"></i>
                                    </span> تماس </NavLink>
                            </li>
                            <li>
                            



                                {/* <button id="theme-toggle" type="button" className="dark-light-btn w-[44px] h-[44px] ml-2">
                                    <i id="theme-toggle-dark-icon" className="fa-solid text-xl fa-moon "></i>
                                    <i id="theme-toggle-light-icon" className="fa-solid fa-sun text-xl hidden"></i>
                                </button> */}
                      
                      <NavLink  to="/" id="theme-toggle" type="button" className="dark-light-btn w-[44px] h-[44px] ml-2">
                                     {/* <IR title="Persian" className="..." width={25}/> */}
                                   <US title="English" className="..." width={25}/> 
                      </NavLink>
                      

                            </li>
                            
                        </ul>
                    </nav>


          
                    <nav id="navbar" className={(showMenu ? "hidden" : "")}>
                        <ul
                            className="block rounded-b-[20px] shadow-md absolute left-0 top-20 z-[22222222222222] w-full bg-white dark:bg-[#1d1d1d]">
                            <li>
                                <NavLink  to="/fa/" className={({ isActive }) => (isActive ? mobActiveLink : mobNormalLink)}>
                                     <span className="mr-2 text-xl">
                                        <i className="fa-solid fa-house"></i>
                                    </span> خانه </NavLink>
                            </li>
                            <li>
                                <NavLink  to="/fa/about" className={({ isActive }) => (isActive ? mobActiveLink : mobNormalLink)}>
                                      <span className="mr-2 text-xl">
                                        <i className="fa-regular fa-user"></i>
                                    </span> درباره </NavLink>
                            </li>
                            <li>
                                <NavLink  to="/fa/resume" className={({ isActive }) => (isActive ? mobActiveLink : mobNormalLink)}>
                                      <span className="mr-2 text-xl">
                                        <i className="fa-regular fa-file-lines"></i>
                                    </span> رزومه </NavLink>
                            </li>
                             {/* <li>
                                <NavLink  to="/works" className={({ isActive }) => (isActive ? mobActiveLink : mobNormalLink)}>
                                     <span className="mr-2 text-xl">
                                        <i className="fas fa-briefcase"></i>
                                    </span> Works </NavLink>
                            </li> */}
                            {/* <li>
                                <NavLink  to="/fa/portfolio" className={({ isActive }) => (isActive ? mobActiveLink : mobNormalLink)}>
                                     <span className="mr-2 text-xl">
                                        <i className="fa-brands fa-blogger"></i>
                                    </span> کارها </NavLink>
                            </li>  */}
                            <li>
                                <NavLink  to="/fa/contact" className={({ isActive }) => (isActive ? mobActiveLink : mobNormalLink)}>
                                     <span className="mr-2 text-xl">
                                        <i className="fa-solid fa-address-book"></i>
                                    </span> تماس </NavLink>
                            </li>
                        </ul>
                    </nav>
     
                </header>
   
  )
}
